<template>
  <div class="error-wrapper">
    <div class="error-block">
      <svg
        width="64"
        height="64"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M85.57,446.25H426.43a32,32,0,0,0,28.17-47.17L284.18,82.58c-12.09-22.44-44.27-22.44-56.36,0L57.4,399.08A32,32,0,0,0,85.57,446.25Z"
          style="
            fill: none;
            stroke: #cb0000;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 32px;
          "
        />
        <path
          d="M250.26,195.39l5.74,122,5.73-121.95a5.74,5.74,0,0,0-5.79-6h0A5.74,5.74,0,0,0,250.26,195.39Z"
          style="
            fill: none;
            stroke: #cb0000;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 32px;
          "
        />
        <path d="M256,397.25a20,20,0,1,1,20-20A20,20,0,0,1,256,397.25Z" fill="#cb0000"/>
      </svg>
      <div class="error-title">Произошла ошибка</div>
      <div class="error-text">Отсутствует обязательный атрибут "source"</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
   metaInfo: {
    title: "Ошибка",
  },
};
</script>

<style lang="scss" scoped>
.error {
  &-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }
  &-block {
    padding: 16px;
    background-color: #fbd4d4;
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

 &-title {
    margin: 16px 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }
}
</style>